<template>
  <!-- card-hover-shadow  -->
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="isBtn"
        value="right"
        v-bind="attrs"
        color="primary"
        outlined
        v-on="on"
      >
        {{ $t("Mon colis a un soucis, Demarrer un litige") }}
      </v-btn>
      <v-list-item
        v-else
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>{{ $t("Mon colis a un soucis, Demarrer un litige") }}</v-list-item-title>
      </v-list-item>
      <!--      <v-btn
        v-bind="attrs"
        color="primary"
        v-on="on"
      >
        {{ $t("Disponible") }}
      </v-btn>-->
    </template>

    <v-card>
      <v-card-title>
        {{ $t("Création d'un litige") }}
        <v-spacer />
        <v-btn
          class=""
          absolute
          right
          icon
          color="secondary"
          text
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <p>{{ $t("Confirme la creation du litige?") }}</p>

        <!--          <v-text-field :label="$t('Quantité')" type="number" outlined
                        v-model="item.quantity"
                        :min="0"
                        :max="item.quantity"
          />-->

        <v-textarea
          :label="$t('Veuillez entrer le sujet du litige')"
          outlined
        />
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          text
          @click="dialog=false"
        >
          {{ $t("Annuler") }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          :loading="loading"
          @click="save"
        >
          {{ $t("Créer le litige") }}
        </v-btn>
      </v-card-actions>
      <v-snackbar
        v-model="snackbar.active"
        :color="snackbar.color"
      >
        {{ snackbar.message }}
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import VendorLink from '../VendorLink'
  import AddToCartButton from './AddToCartButton'

  export default {
    name: 'CreateDispute',
    components: {},
    props: {
      item: {
        type: Object,
        default: () => {},
      },
      isBtn: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        dialog: false,
        loading: false,
        snackbar: {
          active: false,
          message: '',
          color: 'red',
        },
      }
    },
    computed: {
    },
    mounted () {
      this.loading = false
    },
    created () {
      this.loading = false
    },
    methods: {
      save () {
        this.loading = true
        axios({
          method: 'post',
          url: `/package/active/item/${this.package_id}/${this.item.id}`,
          data: { },
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).catch(error => {
          // this.loading = false
          this.loading = false
          console.log(error)
          // eslint-disable-next-line consistent-return
        }).then((response) => {
          this.loading = false
          if (response.data.error) {
            this.snackbar.message = response.data.message
            this.snackbar.active = true
          } else {
            this.dialog = false
            this.$emit('removed')
          }
        }).then(() => {
          this.loading = false
        })
      },

    },
  }
</script>
<style lang="scss" scoped>

    .br-t-8 {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }
  .card-hover {
    &:hover {
      .card-hover-icon {
        display: block;
      }
    }
    .card-hover-icon {
      display: none;
    }
  }

</style>
